import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';


class IncidentsTable extends React.Component {

  state = {
  };

  createSortHandler = (property) => {
    this.props.onRequestSort(property);
  };

  render() {
    const {
      classes,
      incidents,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
      totalCount,
      order,
      orderBy
    } = this.props;

    return (
      <Paper className={classes.paper}>
        <Table style={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'full_name'}
                  direction={orderBy === 'full_name' ? order : 'asc'}
                  onClick={() => this.createSortHandler('full_name')}
                  hideSortIcon={false}
                >
                  Isiku nimi
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'piirkond'}
                  direction={orderBy === 'piirkond' ? order : 'asc'}
                  onClick={() => this.createSortHandler('piirkond')}
                  hideSortIcon={false}
                >
                  Piirkond
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'keel'}
                  direction={orderBy === 'keel' ? order : 'asc'}
                  onClick={() => this.createSortHandler('keel')}
                  hideSortIcon={false}
                >
                  Keel
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'elukoht'}
                  direction={orderBy === 'elukoht' ? order : 'asc'}
                  onClick={() => this.createSortHandler('elukoht')}
                  hideSortIcon={false}
                >
                  Elukoht
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => this.createSortHandler('status')}
                  hideSortIcon={false}
                >
                  Staatus
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.map(n => {
              return (
                <TableRow
                  hover
                  onClick={e => navigate("/victim/" + n.kliendi_nr + "/" + n.id)}
                  key={n.id}
                >
                  <TableCell>
                    {n.first_name} {n.last_name}
                  </TableCell>
                  <TableCell>
                    {n.piirkond}
                  </TableCell>
                  <TableCell>
                    {n.keel}
                  </TableCell>
                  <TableCell>
                    {n.elukoht}
                  </TableCell>
                  <TableCell>
                    {n.status === 'in_progress' ? 'Töös' : n.status === 'finished' ? 'Lõpetatud' : ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 20, 50, 100, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
  
};

IncidentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default IncidentsTable;